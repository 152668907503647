.banner {
  position: relative;
  height: 248px;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  overflow: hidden;
}

.banner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  filter: brightness(70%);
  z-index: -1;
}

.companyContainer {
  background-color: rgb(255, 255, 255);
  padding-bottom: 1px;
  min-height: 100vh;
}

.productList {
  margin: 20px 0px 40px 0px;
}

.productsContainer {
  max-width: 1200px;
  background-color: #fff;
  margin: auto;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.menuImage {
  width: 88px;
  height: 110px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
}

.alert {
  display: flex;
  justify-content: "center";
  align-items: "center";
  text-align: "center";
  width: 100%;
}

.swButtonBase {
  box-shadow: inset 0px 0px 0px 0px #594ed600;
  transition: 0.2s;
}

.swButtonBase:active {
  box-shadow: inset 0px 0px 0px 2px #594ed6;
}

/* =========| CopyRight Footer |========== */
.copyRight {
  display: flex;
  align-items: center;
  font-size: 11px;
  gap: 4px;
  background-color: #fff;
}

@media (max-width: 310px) {
  .copyRight {
    font-size: 3.9vw;
  }
}
