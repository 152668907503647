.stickyDiv {
  position: sticky;
  top: 0px;
  background-color: #f5f5f5;
  z-index: 1;
  border-bottom: 1px solid #dadada50;
}

.infoBox {
  margin: 16px;
  margin-top: 0px;
  color: var(--Tiber, #064c3d);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.3px;
  border-radius: 9999px;
  background: rgba(0, 154, 52, 0.1);
  display: flex;
  padding: 2px 8px 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: fit-content;
}

.bottomStickyDiv {
  position: sticky;
  bottom: 0;
  padding-bottom: 100px;
  background-color: #f5f5f5;
  border-top: 1px solid #dadada50;
}

.flexDivSecondary {
  display: flex;
  align-items: center;
  padding: 12px 16px 12px 16px;
  gap: 16px;
  cursor: pointer;
}

.addButton {
  display: flex;
  background-color: rgba(0, 0, 0, 0.06);
  width: 48px;
  height: 48px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.addButtonText {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.15px;
}

.totalText {
  display: flex;
  justify-content: flex-end;
  margin: 16px;
  gap: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.4px;
}

.quantityInputIconButton {
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100px !important;
  cursor: pointer;
}
