/* FONTS USED */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300;1,400;1,500&family=Poppins:wght@100;300;600;800&display=swap");

@font-face {
  font-family: "Nohemi";
  src: local("Nohemi"),
    url("./assets/Font-Family/Nohemi-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Uncut Sans Variable";
  src: local("Uncut Sans Variable"),
    url("./assets/Font-Family/Uncut-Sans-VF.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  background-color: rgb(255, 255, 255);
  z-index: 0;
  width: 100%;
  min-height: 100vh;
  position: relative;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.homeContainer {
  margin-inline: 40px;
}

@media (max-width: 768px) {
  .homeContainer {
    padding: 0px 20px;
    margin: auto;
  }
}
