.container {
  position: relative;
  display: flex;
  width: 627px;
  padding: 6px 6px 6px 32px;
  align-items: center;
  border-radius: 999px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: var(--Icon-primary, #fff);
  margin: auto;
  gap: 12px;
  height: 70px;
}

.inputContainer {
  display: flex;
  gap: 12px;
  width: 100%;
}

.input {
  outline: none;
  border: none;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-family: "Uncut Sans Variable";
}

.inputButton {
  display: flex;
  padding: 0px 32px;
  height: 72px;
  max-height: 72px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #fff;
  border: none;
  border-radius: 50px;
  background: var(--CTA, #594ed6);
  width: 80%;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-family: "Uncut Sans Variable";
  cursor: pointer;
  transition: 0.3s;
}

.inputButton:hover {
  background: var(--CTA-hover, #6d65d2);
}

.inputButtonText {
  height: auto;

  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  display: -moz-box;
  line-height: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-line-clamp: 2;
}

.errorMessage {
  position: absolute;
  color: rgb(255, 0, 0);
  font-size: 14px;
  margin-top: 5px;
  margin-left: 70px;
  bottom: -40px;
  left: -30px;
}

/* ===================| Media Query |=================== */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    border: none;
    gap: 0;
    padding: 0;
    width: 100%;
    align-items: normal;
  }
  .inputButton {
    width: 100%;
    height: 52px;
    padding: 16px 32px;
    font-size: 16px;
    margin-top: 16px;
  }

  .input {
    width: auto;
    font-size: 16px;
  }

  .inputContainer {
    border-radius: 999px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 16px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .errorMessage {
    text-align: left;
    position: relative;
    margin: 5px 0px 0px 30px;
    bottom: 0;
    left: 0;
  }
}
