.navContainer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(26px);
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.navContent {
  padding: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navLogo {
  height: 16px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.navButton {
  border: none;
  color: #0e0e0e;
  padding: 9px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  transition: 0.3s;
}

.navButton:hover {
  background: #e0e0e0;
}

/* ===================| Media Query |=================== */
@media (max-width: 768px) {
  .navContent {
    justify-content: space-between;
  }
  .navLogo {
    left: 0;
    transform: translateX(0);
    position: relative;
  }
}
