.QRContainer {
  height: 252px;
  position: relative;
}

.QRCard {
  height: 172px;
  width: 438px;
  margin: 40px;
  margin-left: auto;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: var(--buttons-primiary, #594ed6);
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.QRCardText {
  width: 253px;
  padding: 19px 6px 0px 19px;
  color: #fff;
  font-family: Nohemi;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.4px;
  z-index: 1;
}

.QRCode {
  margin: 12px 12px 12px 0px;
  padding: 8px;
  background-color: #fff;
  border-radius: 10px;
  z-index: 1;
}

.cardEffect {
  position: absolute;
  border-radius: 377px;
  right: -90px;
  top: -100px;
  background: rgba(27, 233, 109, 0.5);
  filter: blur(38px);
  width: 377px;
  height: 150px;
}

.cardEffect2 {
  position: absolute;
  right: 200px;
  top: 70px;
  border-radius: 286px;
  background: rgba(167, 27, 233, 0.47);
  filter: blur(38px);
  width: 286px;
  height: 150px;
}

.cardImage {
  position: absolute;
  right: 205px;
  bottom: -37px;
  z-index: 1;
  font-size: 82px;
}

.cardImage2 {
  position: absolute;
  right: 256px;
  bottom: 30px;
  z-index: 1;
  font-size: 42px;
}

/* ===================| Media Query |=================== */
@media (max-width: 768px) {
  .QRContainer {
    height: 111px;
  }

  .QRCard {
    border: 1px solid rgba(0, 0, 0, 0.08);
    width: calc(100% - 40px);
    height: 91px;
    background-color: #fff;
    margin: 20px;
    box-sizing: border-box;
  }

  .QRCardText {
    color: black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.6px;
    padding: 0px;
    margin: auto;
    width: auto;
    max-width: 184px;
  }

  .QRCode {
    padding: 5px;
    max-width: 65px;
    max-height: 65px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.12);
    margin: 8px 8px 8px 0px;
    position: absolute;
    right: 0;
  }

  .cardImage {
    left: 10px;
    bottom: -31px;
    font-size: 66px;
  }

  .cardImage2 {
    left: 20px;
    bottom: 45px;
    font-size: 26px;
  }

  .cardEffect {
    display: none;
  }

  .cardEffect2 {
    display: none;
  }
}
