.introContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 866px;
  text-align: center;
}

.introTitlesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1063px;
  margin: auto;
}

.introFrame {
  border-radius: 10px;
  background: rgba(105, 214, 78, 0.24);
  color: #1a4500;
  padding: 5px 16px 6px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 24px;
  max-width: 242px;
  text-align: center;
  font-family: "Uncut Sans Variable";
}

.mainTitle {
  color: rgba(0, 0, 0, 0.92);
  font-size: 56px;
  font-weight: 500;
  line-height: 70px;
  letter-spacing: 2px;
  margin-bottom: 20px;
  font-family: "Nohemi";
}

.secondaryTitle {
  color: var(--Secondary, #3c3c3c);
  font-size: 22px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.22px;
  margin-bottom: 48px;
  max-width: 700px;
  font-family: "Uncut Sans Variable";
}

.howWorksScrollBtn {
  display: none;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  cursor: pointer;
}

.howWorksScrollBtn img {
  margin-right: 11px;
}

/* ===================| Left Image |=================== */
.leftImage {
  position: absolute;
  z-index: -1;
  right: -160px;
  top: -345px;
  overflow: hidden;
}

/* ===================| Right Image |=================== */

.rightImage {
  position: absolute;
  z-index: -1;
  right: 0;
  display: none;
  overflow: hidden;
  top: 95px;
  right: 0;
}

/* ===================| Media Query |=================== */
@media (max-width: 1200px) {
  .leftImage {
    display: none;
  }
}
@media (max-width: 768px) {
  .introContainer {
    text-align: left;
    padding: 32px 0px;
    height: auto;
    padding: 104px 0px 32px 0px;
    border-bottom: 1px solid #ededed;
  }

  .introTitlesContainer {
    text-align: left;
    align-items: normal;
    padding-right: 32px;
  }

  .mainTitle {
    font-size: 32px;
    line-height: 36px;
    padding-right: 5px;
  }

  .secondaryTitle {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.18px;
    margin-bottom: 24px;
  }

  .introFrame {
    font-size: 13px;
    max-width: 190px;
    margin-bottom: 16px;
    padding: 3px 10px 4px 10px;
  }

  .rightImage {
    display: block;
  }

  .howWorksScrollBtn {
    display: flex;
  }
}
