.input {
  width: 100%;
  padding: 24px;
  border-radius: 999px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 18px;
  box-sizing: border-box;
  outline: none;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-family: "Uncut Sans Variable";
  font-weight: 500;
  background: rgba(0, 0, 0, 0.02);
  font-style: normal;
}

.inputError {
  border-color: rgb(255, 0, 0);
}

.errorMessage {
  color: rgb(255, 0, 0);
  font-size: 14px;
  margin-top: 8px;
  margin-left: 30px;
}

/* ===================| Media Query |=================== */
@media (max-width: 768px) {
  .input {
    padding: 20px 28px;
    font-size: 16px;
    height: 56px;
  }
}
