.StepsContainer {
  text-align: center;
  row-gap: 40px;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 56px;
  margin: 104px 0px;
}

.StepsTitle {
  font-family: "Nohemi";
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: 1.2px;
  margin-bottom: 16px;
}

.StepsText {
  margin: auto;
  color: var(--Secondary, #3c3c3c);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.22px;
  max-width: 840px;
  font-family: "Uncut Sans Variable";
}

.StepsFlex {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 40px;
  max-width: 1958px;
  gap: 82px;
  font-size: 18px;
  text-align: left;
}

.StepsBox {
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.StepsBox img {
  width: 100%;
  max-width: 300px;
  object-fit: contain;
  margin: auto;
}

.ProtTitle {
  color: var(--buttons-primiary, #594ed6);
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.18px;
  display: flex;
  font-family: "Uncut Sans Variable";
}

.ArrowContainer {
  position: absolute;
}

.Arrow1 {
  position: relative;
  left: -200px;
  top: 190px;
}

.Arrow2 {
  position: relative;
  left: 220px;
  top: 155px;
}
/* ===================| Media Query |=================== */
@media (max-width: 1100px) {
  .StepsFlex {
    flex-direction: column;
    position: relative;
    margin-top: 0;
    gap: 50px;
  }

  .ArrowContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Arrow1 {
    position: relative;
    left: 0;
    top: 0;
    transform: rotate(120deg);
    width: 80px;
  }
  .Arrow2 {
    position: relative;
    left: 0;
    top: 0;
    transform: rotate(120deg);
    width: 90px;
  }
  .StepsBox {
    flex-direction: column-reverse;
    gap: 16px;
  }
  .StepsContainer {
    padding-bottom: 56px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--Secondary, #ededed);
    gap: 32px;
    margin: 32px 0px;
  }
  .StepsBox img {
    max-width: 220px;
  }
}
