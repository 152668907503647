.infoContaier {
  display: flex;
  max-width: 1282px;
  margin: auto;
  height: 1046px;
  justify-content: space-between;
}

.infoLeftBox {
  display: flex;
  flex-direction: column;
  gap: 56px;
  max-width: 736px;
  margin: auto 0;
}

.infoTitle {
  color: var(--Black, #0e0e0e);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px; /* 140% */
  letter-spacing: 1.2px;
  font-family: "Nohemi";
}

.screenshotContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 409px;
  max-height: 828px;
  margin: auto 0;
  position: relative;
  overflow: hidden;
}

.phoneScreenshot {
  content: url("../../../../assets/HomePage/img_screenshot.webp");
  width: 100%;
  object-fit: contain;
  object-position: top;
  z-index: -1;
}

.gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.infoFlex {
  display: flex;
  margin: auto;
}

.infoFlexContainerContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 175px;
}

.infoFlexTitle {
  color: var(--CTA, #594ed6);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px; /* 140% */
  letter-spacing: 1.2px;
  font-family: "Nohemi";
}

.infoFlexDescription {
  color: var(--Secondary, #3c3c3c);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
  letter-spacing: 0.32px;
  font-family: "Manrope";
}

.divider {
  width: 1px;
  height: 100%;
  background: var(--Secondary, #ededed);
  margin: 0px 40px;
}

.infoFlexContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

/* ===================| Media Query |=================== */
@media (max-width: 1300px) {
  .infoContaier {
    flex-direction: column-reverse;
    gap: 56px;
    height: auto;
    margin-bottom: 40px;
  }

  .screenshotContainer {
    margin: auto;
  }

  .infoLeftBox {
    margin: auto;
  }
}
@media (max-width: 768px) {
  .infoContaier {
    gap: 24px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--Secondary, #ededed);
  }
  .notifyInput {
    display: none;
  }

  .infoFlexContainerContent {
    width: auto;
    margin: auto;
    gap: 0;
  }

  .infoFlexTitle {
    font-size: 28px;
    height: auto;
    line-height: 40px;
    margin: 8px 0;
  }

  .infoFlex {
    flex-direction: column;
  }

  .infoFlexContainer {
    flex-direction: column;
  }

  .infoTitle {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.6px;
  }

  .infoLeftBox {
    gap: 28px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--Secondary, #ededed);
    margin: 20px 0px;
  }

  .gradient {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 65%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  .phoneScreenshot {
    margin-bottom: -72%;
  }
}

/* ================| Img Retina |================ */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .phoneScreenshot {
    content: url("../../../../assets/HomePage/img_screenshot@2x.webp");
  }
}
