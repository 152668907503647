.checkboxInput {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 5px;
  vertical-align: middle;
  border: 1.5px solid var(--Border-32, rgba(0, 0, 0, 0.24));
  appearance: none;
  outline: none;
  cursor: pointer;
  margin: auto 0;
}

.checkboxInput:checked {
  padding: 2px;
  content: url("assets/icons/ic_check_white.svg");
  background-color: #5d37f8;
  border: none;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Uncut Sans Variable";
  margin-left: 10px;
  font-size: 17px;
}

.errorMessage {
  color: rgb(255, 0, 0);
  font-size: 12px;
  margin-top: 8px;
  margin-left: 40px;
}

.termsLink {
  color: var(--Primary-100, #5d37f8);
  text-decoration: none;
  margin-left: 5px;
}
