.footerContainer {
  max-width: 1134px;
  padding: 72px;
  padding-bottom: 27px;
  border-radius: 40px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: var(--White, #fff);
  margin: auto;
}

.footerContainerFlex {
  display: flex;
  gap: 88px;
  justify-content: center;
  align-items: flex-start;
}

.footerContainerLeft {
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 526px;
  width: 100%;
}

.footerContainerRight {
  display: flex;
  flex-direction: column;
  gap: 28px;
  max-width: 526px;
  width: 100%;
}

/* ========================== */

.footerContainerText {
  font-family: Nohemi;
  font-size: 58px;
  font-weight: 500;
  line-height: 70px;
  letter-spacing: 2px;
}

.buttonPrimary {
  border: none;
  color: #fff;
  border-radius: 50px;
  background: var(--CTA, #594ed6);
  padding: 20px 32px;
  font-size: 18px;
  line-height: 20px;
  width: max-content;
  font-family: "Uncut Sans Variable";
  cursor: pointer;
  transition: all 0.3s;
}

.buttonPrimaryLoading {
  border: none;
  color: #fff;
  border-radius: 50px;
  background: var(--CTA, #594ed6);
  padding: 5px 32px;
  font-size: 18px;
  line-height: 20px;
  width: max-content;
  font-family: "Uncut Sans Variable";
  cursor: pointer;
  transition: all 0.3s;
}

.buttonPrimary:hover {
  background: var(--CTA-hover, #6d65d2);
}

.footerIcon {
  font-size: 78px;
  line-height: 72px;
  letter-spacing: 1px;
}

.copyRight {
  display: flex;
  gap: 5px;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 45px;
  align-items: center;
  justify-content: center;
}

/* ===================| Media Query |=================== */
@media (max-width: 999px) {
  .footerContainer {
    padding: 96px 56px 27px 56px;
  }
  .footerContainerFlex {
    gap: 24px;
  }
  .copyRight {
    margin-top: 69px;
  }
}
@media (max-width: 768px) {
  .footerContainer {
    border: none;
    padding: 40px 0px;
  }
  .copyRight {
    margin-top: 40px;
  }
  .footerContainerFlex {
    flex-direction: column;
    gap: 28px;
  }
  .buttonPrimary {
    width: 100%;
    padding: 20px 32px;
  }

  .footerContainerLeft {
    max-width: 100%;
  }

  .footerContainerRight {
    max-width: 100%;
  }

  .footerIcon {
    display: none;
  }

  .footerContainerText {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 450px) {
  .copyRight {
    font-size: 3.3dvw;
  }
}
