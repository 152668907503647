.textarea {
  width: 100%;
  height: 157px;
  padding: 28px;
  border-radius: 40px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  outline: none;
  resize: none;
  font-size: 18px;
  font-family: "Uncut Sans Variable";
  font-weight: 500;
  background: rgba(0, 0, 0, 0.02);
}
