.detailsDialog {
  margin: auto;
  margin-bottom: 0;
  height: 90%;
  max-width: 800px;
}

.flexColumnContainer {
  padding: 12px;
  z-index: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.stickyHeader {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1;
}

.productContainer {
  background-color: #fff;
  z-index: 0;
  border-radius: 16px;
  width: 100%;
  padding: 16px;
  box-shadow: 0px 5px 30px -5px rgba(0, 0, 0, 0.25);
}

.portionsContainer {
  background-color: #fff;
  border-radius: 16px;
  width: 100%;
  padding: 16px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
}

.portionsContainerHeader {
  margin-bottom: 4px;
  justify-content: space-between;
  align-items: center;
}

.portionsContainerHeaderRequired {
  background-color: rgba(255, 0, 0, 0.08);
  color: #a30000;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  font-style: normal;
  line-height: 20px;
}

.banner {
  position: relative;
  height: 248px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.totalPrice {
  font-size: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.radioBox {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid rgba(0, 0, 0, 0.2);
  appearance: none;
  outline: none;
  cursor: pointer;
}
.radioBox:checked {
  border: 6.5px solid #000000;
}

.checkBox {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 4px;
  vertical-align: middle;
  border: 2px solid rgba(0, 0, 0, 0.2);
  appearance: none;
  outline: none;
  cursor: pointer;
}
.checkBox:checked {
  padding: 2px;
  content: url("assets/icons/ic_check_white.svg");
  background-color: #000000;
  border: none;
}

.price {
  font-size: 13px;
  letter-spacing: 0.4px;
  color: #646464;
  line-height: 16px;
}

.buttonBase {
  display: block !important;
  width: 100%;
  font-size: 16px;
  font-family: Roboto;
}

.optionComponet {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  height: 40px;
  align-items: center;
  margin: 8px 0px 8px 0px;
}

/* ========== Extras ========= */

.extrasContainer {
  background-color: #fff;
  border-radius: 16px;
  width: 100%;
  padding: 16px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
}
